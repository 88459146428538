// 加载 request 模块
import {request} from '@/utils'

export const get_userinfo = params => {
    return request({
        method: 'GET',
        url: '/api/get_userinfo',
        params,
    })
}
